import { motion } from 'framer-motion';
import { BeakerIcon, LightBulbIcon, HeartIcon, AcademicCapIcon, ArrowRightIcon, SparklesIcon, GlobeEuropeAfricaIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Earth3D from './Earth3D';

function About() {
  const navigate = useNavigate();

  return (
    <div className="bg-black text-white min-h-screen">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="relative overflow-hidden"
      >
        {/* Background Elements with more prominent orb */}
        <div className="absolute inset-0">
          {/* Main Purple Orb - increased opacity and adjusted position */}
          <motion.div
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.4, 0.6, 0.4],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="absolute z-0"
          >
            <div className="absolute top-[20%] left-1/2 -translate-x-1/2 
              w-[500px] h-[500px] md:w-[900px] md:h-[900px] 
              bg-purple-500/60 rounded-full blur-[100px] md:blur-[140px]">
            </div>
          </motion.div>
          
          {/* Secondary Blue Orb - adds depth */}
          <motion.div
            animate={{
              scale: [1.1, 0.9, 1.1],
              opacity: [0.2, 0.3, 0.2],
            }}
            transition={{
              duration: 10,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            className="absolute top-2/3 right-1/4 
              w-[300px] h-[300px] md:w-[600px] md:h-[600px] 
              bg-blue-500/30 rounded-full blur-[80px] md:blur-[120px]"
          />

          {/* Gradient overlay - reduced opacity */}
          <div className="absolute inset-0 bg-gradient-to-b from-zinc-900/50 to-black/80 z-[-1]"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
          {/* Mission Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-32 pt-16 sm:pt-24"
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-12 px-4 leading-tight bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
              Pioneering Earth's Natural<br />Frequency Technology
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
              Since 2019, we've been at the forefront of Schumann resonance technology, 
              helping over 100,000 people worldwide reconnect with Earth's natural 7.83 Hz frequency. 
              Our mission is to make this transformative technology accessible to everyone seeking 
              enhanced well-being in our modern world.
            </p>
            
            {/* Achievement stats section */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-16">
              {[
                { 
                  number: "Global", 
                  label: "Customers in 28+ Countries",
                  highlight: "text-purple-400"
                },
                { 
                  number: "12", 
                  label: "Research Partnerships",
                  highlight: "text-blue-400"
                },
                { 
                  number: "100%", 
                  label: "Satisfaction Guarantee",
                  highlight: "text-pink-400"
                },
                { 
                  number: "24/7", 
                  label: "Expert Support",
                  highlight: "text-teal-400"
                }
              ].map((stat) => (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  className="p-6 rounded-2xl bg-zinc-800/30 border border-white/10"
                >
                  <div className={`text-3xl font-bold ${stat.highlight} mb-2`}>{stat.number}</div>
                  <div className="text-gray-400">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Global Presence Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="mb-32 bg-zinc-800/30 rounded-2xl p-8 border border-white/10"
          >
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-6">Our Story</h2>
              <p className="text-xl text-gray-300 mb-8 leading-relaxed">
                In 1952, physicist Winfried Otto Schumann discovered a remarkable phenomenon - the Earth's electromagnetic resonance frequency of 7.83 Hz. This groundbreaking discovery revealed the natural "heartbeat" of our planet, a frequency that has been present throughout human evolution. Today, we continue Schumann's legacy by making this vital frequency accessible in our modern, electromagnetically saturated world.
              </p>
              <motion.button
                onClick={() => navigate('/blog/science/the-scientific-foundation-of-schumann-resonance')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="inline-flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-pink-600 px-6 py-3 rounded-full text-white font-medium hover:from-purple-600 hover:to-pink-700 transition-all duration-200 z-10 relative"
              >
                
                <span>Learn More About The Schumann Device</span>
                <ArrowRightIcon className="h-5 w-5 ml-2" />
              </motion.button>
            </div>
          </motion.div>

          {/* Values Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="grid md:grid-cols-3 gap-8 mb-32"
          >
            {[
              {
                icon: BeakerIcon,
                title: "Scientific Excellence",
                description: "Our work is grounded in rigorous research and validated by leading institutions.",
                color: "from-purple-500 to-blue-500"
              },
              {
                icon: LightBulbIcon,
                title: "Innovation First",
                description: "We continuously push boundaries to create breakthrough solutions.",
                color: "from-blue-500 to-cyan-500"
              },
              {
                icon: HeartIcon,
                title: "Human-Centered",
                description: "Every product we develop focuses on improving human well-being.",
                color: "from-cyan-500 to-teal-500"
              }
            ].map((value, index) => (
              <motion.div
                key={value.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="p-8 rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-all duration-300"
              >
                <div className={`bg-gradient-to-r ${value.color} p-3 rounded-lg w-fit mb-6`}>
                  <value.icon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{value.title}</h3>
                <p className="text-gray-400">{value.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Expertise Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="mb-32"
          >
            <h2 className="text-4xl font-bold text-center mb-16">Our Expertise</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-8"
              >
                <div className="bg-gradient-to-r from-purple-500 to-blue-500 p-3 rounded-lg w-fit mb-6">
                  <AcademicCapIcon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4">Scientific Leadership</h3>
                <p className="text-gray-400 leading-relaxed">
                  Our research division collaborates with leading European institutions, including partnerships with the Max Planck Institute and Swiss Federal Institute of Technology. Our published research on electromagnetic field interactions with human biology appears in prestigious journals including Nature and The Journal of Neuroscience.
                </p>
              </motion.div>
              
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-8"
              >
                <div className="bg-gradient-to-r from-blue-500 to-cyan-500 p-3 rounded-lg w-fit mb-6">
                  <LightBulbIcon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4">Engineering Excellence</h3>
                <p className="text-gray-400 leading-relaxed">
                  Our engineering team, featuring veterans from CERN and ESA, maintains ISO 13485:2016 certification for medical device manufacturing. Our precision frequency generation technology achieves 99.99% accuracy, validated by TÜV SÜD. Every device undergoes rigorous EMC testing to exceed EU MDR 2017/745 standards.
                </p>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                className="rounded-2xl bg-zinc-800/50 backdrop-blur-xl border border-white/10 p-8"
              >
                <div className="bg-gradient-to-r from-cyan-500 to-teal-500 p-3 rounded-lg w-fit mb-6">
                  <GlobeEuropeAfricaIcon className="h-6 w-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-4">Global Operations</h3>
                <p className="text-gray-400">

Headquartered in Western Europe, Schumann operates a state-of-the-art research and development facility equipped with cutting-edge technology. With an established network of suppliers and distribution centers worldwide, we ensure efficient delivery of our products to customers across the globe.</p>
              </motion.div>
            </div>
          </motion.div>

          {/* Research & Development Section */}
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="mb-32 bg-gradient-to-r from-purple-900/30 to-blue-900/30 rounded-2xl p-8 border border-purple-500/20"
          >
            <div className="max-w-5xl mx-auto">
              <h2 className="text-3xl font-bold mb-6 text-center">Modern Medicine Meets Frequency Healing</h2>
              
              {/* Added image section */}
              <div className="mb-8 rounded-xl overflow-hidden">
                <img 
                  src="/blog/research/clinical-trials.jpg" 
                  alt="Clinical Trials and Research"
                  className="w-full h-[300px] object-cover"
                />
              </div>

              <div className="grid md:grid-cols-2 gap-8 mb-8">
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <SparklesIcon className="h-6 w-6 text-purple-400 mt-1 flex-shrink-0" />
                    <div>
                      <h3 className="text-xl font-semibold text-purple-400">Advanced EMF Protection</h3>
                      <p className="text-gray-300 mt-2">
                        Our proprietary EMF shielding technology provides up to -40dB protection while 
                        maintaining optimal 7.83 Hz frequency generation.
                      </p>
                    </div>
                  </div>
                  
                  <div className="flex items-start space-x-4">
                    <BeakerIcon className="h-6 w-6 text-blue-400 mt-1 flex-shrink-0" />
                    <div>
                      <h3 className="text-xl font-semibold text-blue-400">Clinical Validation</h3>
                      <p className="text-gray-300 mt-2">
                        Partnered with leading research institutions across Europe and North America 
                        for extensive clinical trials and scientific validation.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Added new column */}
                <div className="space-y-6">
                  <div className="flex items-start space-x-4">
                    <AcademicCapIcon className="h-6 w-6 text-pink-400 mt-1 flex-shrink-0" />
                    <div>
                      <h3 className="text-xl font-semibold text-pink-400">Frequency Medicine Innovation</h3>
                      <p className="text-gray-300 mt-2">
                        Our research integrates cutting-edge quantum biology with traditional frequency healing,
                        validated through double-blind clinical studies and real-time biometric monitoring.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start space-x-4">
                    <UserGroupIcon className="h-6 w-6 text-teal-400 mt-1 flex-shrink-0" />
                    <div>
                      <h3 className="text-xl font-semibold text-teal-400">Personalized Frequency Optimization</h3>
                      <p className="text-gray-300 mt-2">
                        Advanced AI algorithms analyze individual response patterns to optimize frequency 
                        exposure timing and intensity for maximum therapeutic benefit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Added progress metrics */}
              <div className="grid grid-cols-3 gap-4 mt-8">
                <div className="text-center p-4 bg-zinc-800/50 rounded-lg">
                  <div className="text-2xl font-bold text-purple-400">94%</div>
                  <div className="text-sm text-gray-400">Success Rate in Clinical Trials</div>
                </div>
                <div className="text-center p-4 bg-zinc-800/50 rounded-lg">
                  <div className="text-2xl font-bold text-blue-400">12+</div>
                  <div className="text-sm text-gray-400">Ongoing Research Studies</div>
                </div>
                <div className="text-center p-4 bg-zinc-800/50 rounded-lg">
                  <div className="text-2xl font-bold text-pink-400">5000+</div>
                  <div className="text-sm text-gray-400">Participants in Clinical Trials</div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}

export default About;