import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function Affiliate() {
  return (
    <div className="pt-20 pb-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-r from-purple-900/30 to-pink-900/30 rounded-2xl p-8 border border-purple-500/20"
      >
        <h1 className="text-3xl sm:text-4xl font-bold mb-6">
          Join The Schumann Affiliate Program 🚀
        </h1>

        <div className="space-y-8">
          {/* New: Success Stories Section */}
          <div className="bg-black/20 rounded-xl p-6 backdrop-blur-sm">
            <h2 className="text-2xl font-semibold mb-4">Success Stories 🌟</h2>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="p-4 rounded-lg bg-purple-900/20 border border-purple-500/10">
                <p className="italic text-gray-300">"The Schumann has been amazing to promote to my wellness community. The support team is fantastic!"</p>
                <p className="text-sm text-purple-400 mt-2">- Sarah K., Wellness Influencer</p>
              </div>
              <div className="p-4 rounded-lg bg-purple-900/20 border border-purple-500/10">
                <p className="italic text-gray-300">"The commission rates are fantastic, and the product practically sells itself."</p>
                <p className="text-sm text-purple-400 mt-2">- Mike R., Health Blogger</p>
              </div>
            </div>
          </div>

          {/* Benefits Section */}
          <div>
            <h2 className="text-2xl font-semibold mb-4">Why Partner With Us?</h2>
            <ul className="space-y-3 text-gray-300">
              <li>✨ Earn 8% commission on all sales with your code</li>
              <li>💰 30-day cookie duration</li>
              <li>🎯 Average order value: $84.99</li>
              <li>🛠️ Ready-to-use social media content provided</li>
              <li>💸 Monthly payouts via PayPal (minimum $50)</li>
            </ul>
          </div>

          {/* Enhanced How It Works with Icons */}
          <div>
            <h2 className="text-2xl font-semibold mb-4">How It Works</h2>
            <ol className="space-y-3 text-gray-300">
              <li>1. Apply by emailing us at support@theschumann.com</li>
              <li>2. Get your unique promo code (example: CREATOR8)</li>
              <li>3. Share your code with your audience</li>
              <li>4. Earn 8% when people use your code</li>
            </ol>
          </div>

          {/* New: FAQ Section */}
          <div>
            <h2 className="text-2xl font-semibold mb-4">Frequently Asked Questions</h2>
            <div className="space-y-4">
              {[
                {
                  q: "When do I get paid?",
                  a: "Payments are processed monthly for all earnings over $50."
                },
                {
                  q: "What marketing materials do you provide?",
                  a: "We provide banners, email templates, social media assets, and product images."
                },
                {
                  q: "How long does approval take?",
                  a: "We review applications within 24-48 hours and notify you via email."
                }
              ].map((faq, index) => (
                <div key={index} className="p-4 rounded-lg bg-purple-900/20 border border-purple-500/10">
                  <h3 className="font-semibold text-purple-400">{faq.q}</h3>
                  <p className="text-gray-300 text-sm mt-1">{faq.a}</p>
                </div>
              ))}
            </div>
          </div>

          {/* CTA */}
          <div className="text-center">
            <a
              href="mailto:support@theschumann.com?subject=Affiliate Program Application"
              className="inline-block bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-3 rounded-full font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-200 shadow-lg hover:shadow-purple-500/20"
            >
              Apply Now
            </a>
            <p className="mt-4 text-sm text-gray-400">
              We review all applications within 24-48 hours
            </p>
          </div>

          {/* Requirements */}
          <div className="mt-8 text-sm text-gray-400">
            <p>Requirements for approval:</p>
            <ul className="list-disc list-inside mt-2 space-y-1">
              <li>Active social media presence or website</li>
              <li>Alignment with wellness/health niche</li>
              <li>Professional presentation</li>
              <li>Genuine interest in The Schumann technology</li>
            </ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
}