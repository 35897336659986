import { useCart } from '../context/CartContext';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { ShieldCheckIcon, TruckIcon } from '@heroicons/react/24/solid';
import stripeLogo from '../assets/logos-png/stripe-logo.png';
import backgroundImage from '../assets/path-to-your-image.jpg';
import paypalLogo from '../assets/logos-png/hd-paypal-logo-transparent-background-701751694777793hs5bxpddux.png';
import { useState, useEffect } from 'react';

export default function Cart() {
  const { cart, isCartOpen, setIsCartOpen, removeFromCart, getTotalPrice } = useCart();

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  if (!isCartOpen) return null;

  const handleCheckout = () => {
    const stripeLinks = {
      1: 'https://checkout.theschumann.com/b/8wMaH22qO2yxeWc7ss',
      2: 'https://checkout.theschumann.com/b/7sI3eAc1oc97g0g289',
      3: 'https://checkout.theschumann.com/b/4gwcPa3uS0qpaFW5kn',
      4: 'https://checkout.theschumann.com/b/14k16s4yW5KJ6pG9AK'
    };
    
    const productId = cart[0]?.id || 1;
    window.location.href = stripeLinks[productId] || stripeLinks[1];
  };

  const getPackageLabel = (quantity, id) => {
    if (id === 4) return "Schumann Pro";
    switch (quantity) {
      case 1:
        return "Personal Wellness Package";
      case 2:
        return "Dual Package";
      case 3:
        return "Family Package";
      default:
        return "Package";
    }
  };

  const getOriginalPrice = (quantity, id) => {
    if (id === 4) return 200.00;
    const basePrice = 100.00;
    return basePrice * quantity;
  };

  const getDiscountPercentage = (quantity, id) => {
    if (id === 4) return "20.5%";
    if (quantity === 2) return "55%";
    if (quantity === 3) return "63%";
    return "40%";
  };

  const getDiscountAmount = (quantity, id) => {
    console.log('Processing discount for:', quantity, id);
    if (id === 4) return 41.00;
    if (quantity === 2) return 110.00;
    if (quantity === 3) return 190.00;
    return 40.01;
  };

  const handlePaymentClick = async (paymentMethod) => {
    try {
      const productId = cart[0]?.id || 1;
      const stripeLinks = {
        1: 'https://checkout.theschumann.com/b/8wMaH22qO2yxeWc7ss',
        2: 'https://checkout.theschumann.com/b/7sI3eAc1oc97g0g289',
        3: 'https://checkout.theschumann.com/b/4gwcPa3uS0qpaFW5kn',
        4: 'https://checkout.theschumann.com/b/14k16s4yW5KJ6pG9AK'
      };
      
      // Add payment method preference to URL
      const baseUrl = stripeLinks[productId] || stripeLinks[1];
      const checkoutUrl = `${baseUrl}?prefilled_payment_method=${paymentMethod}`;
      window.location.href = checkoutUrl;
    } catch (error) {
      console.error('Error handling payment:', error);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    setIsSwiping(false);
    
    // Minimum distance required for swipe (in pixels)
    const minSwipeDistance = 50;
    
    if (touchStart && touchEnd) {
      const distance = touchStart - touchEnd;
      
      // If swipe distance is greater than minimum and it's a left-to-right swipe
      if (Math.abs(distance) > minSwipeDistance && distance < 0) {
        setIsCartOpen(false);
      }
    }
    
    // Reset values
    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <div
      className={`fixed inset-y-0 right-0 w-full sm:w-96 bg-black/95 backdrop-blur-sm 
        border-l border-purple-500/20 shadow-2xl transform transition-transform duration-300 ease-in-out z-50
        ${isCartOpen ? 'translate-x-0' : 'translate-x-full'}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        // Add touch feedback during swipe
        transform: isSwiping && touchEnd 
          ? `translateX(${Math.max(0, touchEnd - touchStart)}px)` 
          : isCartOpen ? 'translateX(0)' : 'translateX(100%)',
        transition: isSwiping ? 'none' : 'transform 300ms ease-in-out'
      }}
    >
      {/* Add a visual indicator for swipe */}
      <div className="absolute left-2 top-1/2 -translate-y-1/2 w-1 h-16 
        bg-purple-500/20 rounded-full sm:hidden">
        <div className="absolute inset-0 animate-pulse bg-purple-500/40 rounded-full" />
      </div>

      <AnimatePresence>
        <div className="fixed inset-0 z-50 overflow-hidden">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-black/40 backdrop-blur-sm"
            onClick={() => setIsCartOpen(false)}
          />
          
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: "spring", damping: 25, stiffness: 200 }}
            className="fixed right-0 top-0 bottom-0 w-full bg-zinc-900 shadow-2xl sm:max-w-md md:max-w-lg"
          >
            <div className="flex flex-col h-full">
              <div className="flex items-center justify-between p-4 sm:p-6 border-b border-white/10">
                <h2 className="text-lg sm:text-xl font-semibold text-white">Your Cart</h2>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => setIsCartOpen(false)}
                  className="p-2 rounded-full hover:bg-white/10 transition-colors"
                >
                  <XMarkIcon className="h-5 w-5 sm:h-6 sm:w-6 text-white" />
                </motion.button>
              </div>

              <div className="flex-1 overflow-y-auto p-4 sm:p-6">
                {cart.map((item) => (
                  <motion.div
                    key={item.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="bg-white/5 rounded-xl p-3 sm:p-4 mb-3 sm:mb-4"
                  >
                    <div className="flex justify-between items-start mb-2 sm:mb-4">
                      <div>
                        <h3 className="font-medium text-white text-base sm:text-lg">
                          {item.id === 4 ? "Schumann Pro Model" : item.name}
                        </h3>
                        <p className="text-purple-400 font-medium text-sm sm:text-base">
                          {item.id === 4 ? "Professional Grade Device" : getPackageLabel(item.quantity, item.id)}
                        </p>
                      </div>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={removeFromCart}
                        className="text-red-400 hover:text-red-300 transition-colors"
                      >
                        <XMarkIcon className="h-4 w-4 sm:h-5 sm:w-5" />
                      </motion.button>
                    </div>

                    {item.id === 4 ? (
                      <div className="space-y-1 sm:space-y-2 text-xs sm:text-sm text-gray-400">
                        <div className="flex justify-between text-purple-400 text-xs">
                          <span>📱 Pro Features:</span>
                          <span>Multi-frequency & Digital Display</span>
                        </div>
                        <div className="flex justify-between text-purple-400 text-xs">
                          <span>🎁 Free Bonuses:</span>
                          <span>"Pro EMF Protection Guide" eBook</span>
                        </div>
                        <div className="flex justify-between text-purple-400 text-xs">
                          <span>🎉 Special Gift:</span>
                          <span>Premium Wellness Package</span>
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-1 sm:space-y-2 text-xs sm:text-sm text-gray-400">
                        {item.quantity > 1 && (
                          <div className="flex justify-between text-white/70 text-xs">
                            <span>📦 Devices:</span>
                            <span>{item.quantity}x Schumann Devices</span>
                          </div>
                        )}
                        <div className="flex justify-between text-purple-400 text-xs">
                          <span>🎁 Free Bonuses:</span>
                          <span>"EMF Protection Guide" eBook</span>
                        </div>
                        <div className="flex justify-between text-purple-400 text-xs">
                          <span>🎉 Special Gift:</span>
                          <span>Mystery Wellness Surprise</span>
                        </div>
                      </div>
                    )}
                  </motion.div>
                ))}

                <div className="mt-4 bg-white/5 rounded-xl p-4 space-y-3">
                  <div className="bg-purple-700/20 border border-purple-500/30 rounded-lg p-3">
                    <p className="text-center font-bold text-purple-400 text-lg sm:text-xl animate-pulse">
                      One Step Away from Optimizing Your Life!
                    </p>
                    <p className="text-center text-gray-400 text-sm mt-2">
                      💡 You can adjust quantity in the next step
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-t border-white/10 p-4 sm:p-6 relative">
                <img 
                  src={backgroundImage} 
                  alt="Total Background" 
                  className="absolute inset-0 w-full h-full object-cover opacity-20"
                />
                <div className="flex justify-between mb-6 relative z-10">
                  <span className="text-lg font-semibold text-white">Total</span>
                  <span className="text-lg font-semibold text-purple-400">
                    ${getTotalPrice().toFixed(2)}
                  </span>
                </div>

                <div className="space-y-3 mb-4 relative z-10">
                  <div className="flex items-center justify-between p-4 bg-zinc-900/50 rounded-lg border border-white/5 hover:border-white/10 transition-colors">
                    <div className="flex items-center gap-3">
                      <img 
                        src={stripeLogo} 
                        alt="Stripe" 
                        className="h-7 w-auto object-contain"
                      />
                      <div className="h-6 w-px bg-white/10" />
                      <div className="flex flex-col">
                        <span className="text-white/90 text-sm font-medium">Secure Checkout</span>
                        <span className="text-white/50 text-xs">Bank-level encryption</span>
                      </div>
                    </div>
                    <ShieldCheckIcon className="h-5 w-5 text-[#635BFF]" />
                  </div>

                  <div className="flex items-center justify-between p-4 bg-zinc-900/50 rounded-lg border border-white/5 hover:border-white/10 transition-colors">
                    <div className="flex items-center gap-3">
                      <img 
                        src={paypalLogo} 
                        alt="PayPal" 
                        className="h-7 w-auto object-contain"
                      />
                      <div className="h-6 w-px bg-white/10" />
                      <div className="flex flex-col">
                        <span className="text-white/90 text-sm font-medium">PayPal Available</span>
                        <span className="text-white/50 text-xs">Fast & secure payment</span>
                      </div>
                    </div>
                    <ShieldCheckIcon className="h-5 w-5 text-[#169BD7]" />
                  </div>

                  <div className="flex items-center justify-center gap-4 text-xs sm:text-sm text-gray-400 mb-3">
                    <div className="flex items-center">
                      <ShieldCheckIcon className="h-4 w-4 sm:h-5 sm:w-5 text-green-500 mr-1 sm:mr-2" />
                      <span className="font-medium">SSL Secure</span>
                    </div>
                    <div className="flex items-center">
                      <TruckIcon className="h-4 w-4 sm:h-5 sm:w-5 text-blue-500 mr-1 sm:mr-2" />
                      <span className="font-medium">Free Shipping</span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-yellow-500 mr-1 sm:mr-2">⚙️</span>
                      <span className="font-medium">1 Year Warranty</span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-green-500 mr-1 sm:mr-2">💰</span>
                      <span className="font-medium">90-Day Return</span>
                    </div>
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleCheckout}
                  className="w-full bg-gradient-to-r from-purple-600 to-blue-600 text-white py-3 sm:py-4 px-4 sm:px-6 rounded-lg font-medium hover:from-purple-700 hover:to-blue-700 transition-all duration-200 shadow-lg relative z-10"
                >
                  Secure Checkout
                </motion.button>
              </div>
            </div>
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
  );
} 