import React from 'react';

function ReturnPolicy() {
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h1 className="text-3xl font-bold mb-8 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
        Return Policy
      </h1>
      
      <div className="prose prose-lg max-w-none">
        <h2 className="text-2xl font-semibold mb-4">Our Return Policy</h2>
        
        <p className="mb-4">
          We want you to be completely satisfied with your purchase. We offer one of the most generous return policies in the industry because we stand behind our product's quality and effectiveness.
        </p>

        <h3 className="text-xl font-semibold mb-3">90-Day Satisfaction Guarantee</h3>
        <p className="mb-4">
          You have 90 days from the date of delivery to initiate a return.
        </p>

        <h3 className="text-xl font-semibold mb-3">Return Conditions</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Item must be unused and in the same condition that you received it</li>
          <li>Item must be in the original packaging</li>
          <li>Proof of purchase is required</li>
        </ul>

        <h3 className="text-xl font-semibold mb-3">How to Initiate a Return</h3>
        <p className="mb-4">
          To start the return process, please contact our customer service team at{' '}
          <a href="mailto:support@theschumann.com" className="text-purple-600 hover:text-purple-800">
            support@theschumann.com
          </a>
        </p>

        <h3 className="text-xl font-semibold mb-3">Refund Process</h3>
        <p className="mb-4">
          Once we receive and inspect the returned item, we will notify you about the status of your refund.
          If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment.
        </p>
      </div>
    </div>
  );
}

export default ReturnPolicy; 