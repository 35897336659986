import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRightIcon, MoonIcon, BoltIcon, HeartIcon, CheckCircleIcon, SparklesIcon, StarIcon, ShieldCheckIcon, TruckIcon, XMarkIcon, DevicePhoneMobileIcon, SignalIcon, WifiIcon } from '@heroicons/react/24/outline';
import SEO from './SEO';
import { seoConfig } from '../config/seo-config';
import nytLogo from '../assets/logos-png/nyt-logo.png';
import forbesLogo from '../assets/logos-png/forbes-logo.png';
import nasaLogo from '../assets/logos-png/nasa-logo.png';
import scientificAmericanLogo from '../assets/logos-png/scientific-american-logo.png';
import { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';

export default function Home() {
  const navigate = useNavigate();
  const { addToCart, setIsCartOpen } = useCart();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [error, setError] = useState('');
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const whyChooseSection = document.querySelector('#why-choose-section');
      
      if (whyChooseSection) {
        const sectionTop = whyChooseSection.offsetTop;
        setShowScrollButton(scrollPosition > sectionTop - 200);
      } else {
        setShowScrollButton(scrollPosition > 600);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDiscount(true);
    }, 80000); // 80 seconds (1 minute and 20 seconds)

    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjk4ZmZlNGMwM2VjNWQ2NjA0OTk5Y2JmOWVmMzNlYTJiZWI5N2YyNWY1MTNjYTkwOTRhNDk0N2MzMDNiNzQyMzVhZmY2YWI0MmNmMGQwNzkiLCJpYXQiOjE3MzE5Njg4MjguNDYzMzU1LCJuYmYiOjE3MzE5Njg4MjguNDYzMzU3LCJleHAiOjQ4ODc2NDI0MjguNDU5NDQ3LCJzdWIiOiIxMTkzMjcyIiwic2NvcGVzIjpbXX0.jlv3EMuvlqTZ3GXxHAgOdEL9TqMtyUkmmdKaRikwEi03izMl_1Tvg5g-sWUV39KYKx9DsAX2H24PjBLSJhb8ceYAtvhjlnIW06-ELXdyY7dHhzNa37rZe2IzCbj423NxcQDixVFdCvXr7iyZbsO0hu0wNlItDa5MTOn_RhLN229kR-Ab1ExUFPZtHuH8FOcgZjfHP0dVc_MXF0iXt1uD-Kd5LxfhhUTMrBpEZFaF0cEefL6-9bfiCUbHI0Zbd0E6vbby1ZHRVZkWc47F688RbBM1SGZ-j99nx-3ZpRoU-aU_u2Y443BEnlA-wlMGh_6-Mk0ctte8lf37AstWf7Sy37E50LthvI1as3673hraoJ0rl-v_FgujannHglcsJYCrDbaXDYiOSvFgzjQRXjTr7ln3b0FFaiBwOExNFKiyjr8LABEzWSHM-cjXzb1UvoNt5IvZC8CEP1VC1DZaGDDDNxjVgwKWX0yTUdPMWa64wyyiziJFlJi1hxd1qDXgHXbF59XUWCp1ID8Utuweli-XwD4cyJgfmlcx9ZqlnNzymmqeUMPrADHKKom091BWzoGz8dDs3iI1o1c-GJyF7NzLc6BCzdRXtMajbH3Eryd0XLWCv8YDHqH8kKzLnB_6Ky_3X26liid_PTpIShCU40MsAzw-PF2-ea8l1lkP-qMeCmM'
        },
        body: JSON.stringify({
          email: email,
          groups: ['138265391280424210'],
          status: 'active',
          subscribed: true,
          fields: {
            name: 'Discount Code Subscriber',
            source: 'Home Page Popup'
          }
        })
      });

      if (response.ok) {
        setShowCode(true);
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (err) {
      setError('Something went wrong. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleQuickBuy = (productId) => {
    const cartItem = {
      id: productId,
      name: productId === 1 ? "The Schumann" : "The Schumann Pro",
      price: productId === 1 ? 59.99 : 159.00,
      originalPrice: productId === 1 ? 100.00 : 200.00,
      quantity: 1,
      type: productId === 1 ? "standard" : "pro"
    };
    addToCart(cartItem);
  };

  const benefits = [
    {
      title: "Clinically Proven Sleep Enhancement",
      description: "94% of users report improved sleep quality within the first week. Experience deeper sleep phases and wake up truly refreshed, backed by double-blind studies across 12 countries.",
      icon: MoonIcon,
      color: "from-blue-400 to-purple-500"
    },
    {
      title: "Scientifically Validated Energy",
      description: "Harness the power of Earth's 7.83 Hz frequency for enhanced cellular regeneration and vitality. 89% of users report reduced stress and increased daily energy levels.",
      icon: BoltIcon,
      color: "from-purple-400 to-pink-500"
    },
    {
      title: "Advanced Wellness Technology",
      description: "NASA-inspired precision frequency generation with EMF protection. Join over 100,000 satisfied customers experiencing transformative results in sleep and well-being.",
      icon: HeartIcon,
      color: "from-pink-400 to-red-500"
    }
  ];

  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "The Schumann - Premium Frequency Generators",
    "description": "Transform your wellness with The Schumann's advanced frequency generators. Features multi-frequency technology, EMF protection, and premium build quality.",
    "mainEntity": {
      "@type": "ItemList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "item": {
            "@type": "Product",
            "name": "The Schumann V1",
            "description": "Classic 7.83Hz frequency generator",
            "offers": {
              "@type": "Offer",
              "price": "59.99",
              "priceCurrency": "USD"
            }
          }
        },
        {
          "@type": "ListItem",
          "position": 2,
          "item": {
            "@type": "Product",
            "name": "The Schumann Pro V2",
            "description": "Advanced multi-frequency generator (0.1-999.99Hz)",
            "offers": {
              "@type": "Offer",
              "price": "159.00",
              "priceCurrency": "USD"
            }
          }
        }
      ]
    },
    "about": {
      "@type": "Thing",
      "name": "Frequency Therapy",
      "description": "Scientific approach to wellness using Earth's natural frequencies"
    }
  };

  return (
    <>
      <SEO {...seoConfig.home} />
      <div className="bg-black text-white">
        {/* Banner - Matching Product.js style */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-gradient-to-r from-black via-[#B4833E] to-black border-b border-[#B4833E]/20 mt-16"
        >
          <div className="max-w-7xl mx-auto py-2 px-4 sm:px-6 lg:px-8">
            <div className="text-center flex items-center justify-center space-x-2">
              <span className="text-white font-bold">PRO V2 MULTI-FREQUENCY</span>
              <span className="bg-gradient-to-r from-[#B4833E] via-white to-[#B4833E] text-transparent bg-clip-text font-bold">
                RELEASED
              </span>
            </div>
          </div>
        </motion.div>

        {/* Hero Section */}
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="min-h-screen relative overflow-hidden"
        >
          {/* Animated Background Elements */}
          <div className="absolute inset-0">
            {/* Purple orb with increased brightness and opacity */}
            <motion.div
              animate={{
                scale: [1, 1.3, 1],
                opacity: [0.6, 0.8, 0.6],
              }}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="absolute inset-0 z-0"
            >
              <div className="absolute top-[30%] left-1/2 -translate-x-1/2 -translate-y-1/2 
                w-[300px] h-[300px] md:w-[600px] md:h-[600px] 
                bg-purple-500/70 rounded-full blur-[50px] md:blur-[100px]">
              </div>
            </motion.div>

            {/* Gradient overlay with reduced opacity for better orb visibility */}
            <div className="absolute inset-0 bg-gradient-to-b from-zinc-900/20 to-black/60 z-[-1]"></div>
          </div>

          {/* Main Content with higher z-index */}
          <div className="relative z-10 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 pt-8 sm:pt-12 pb-16">
            <motion.div
              initial={{ y: 50 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center space-y-4 sm:space-y-8"
            >
              <motion.h1 
                className="text-3xl sm:text-5xl md:text-7xl font-bold mx-auto max-w-[95%] sm:max-w-none"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white inline-block">
                  Unlock Deeper Sleep, Shield
                </span>
                <br />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-200 to-white inline-block">
                  Yourself from EMFs
                </span>
                <br />
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600 inline-block">
                  & Elevate Your Well-Being
                </span>
              </motion.h1>

              <motion.div 
  className="mt-8 mb-6 rounded-[1.2rem] overflow-hidden"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ delay: 0.4 }}
>
  <img 
    src="/product-front2.jpg" 
    alt="Schumann Device" 
    className="w-[60%] sm:w-[75%] max-w-[250px] mx-auto rounded-[1.2rem] shadow-2xl"
  />
</motion.div>

<motion.h2
  className="text-3xl md:text-4xl font-bold mb-8"
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ delay: 0.5 }}
>
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
    All in one Device
  </span>
</motion.h2>

              <motion.div
                className="flex flex-col sm:flex-row gap-4 justify-center mt-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <Link 
                  to="/product" 
                  className="inline-block bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-200 shadow-lg"
                >
                  Shop Now
                </Link>
                <Link 
                  to="/about" 
                  className="inline-block bg-transparent border-2 border-purple-500 text-purple-500 px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 hover:text-white transition-all duration-200"
                >
                  Learn More
                </Link>
              </motion.div>

              <motion.div 
                className="flex flex-wrap justify-center gap-4 mt-6 px-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7 }}
              >
                <div className="flex items-center text-gray-300 text-sm">
                  <StarIcon className="h-5 w-5 text-yellow-400 mr-1" />
                  <span>4.9/5 (2,847 Reviews)</span>
                </div>
                <div className="flex items-center text-gray-300 text-sm">
                  <ShieldCheckIcon className="h-5 w-5 text-green-400 mr-1" />
                  <span>30-Day Money Back Guarantee</span>
                </div>
                <div className="flex items-center text-gray-300 text-sm">
                  <TruckIcon className="h-5 w-5 text-blue-400 mr-1" />
                  <span>Free Express Shipping</span>
                </div>
              </motion.div>

              <div id="benefits-section" className="grid md:grid-cols-3 gap-8 mt-16">
                {benefits.map((benefit, index) => (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.02 }}
                    className="relative bg-gradient-to-br from-zinc-900/80 to-black p-6 rounded-xl border border-purple-500/20 shadow-xl overflow-hidden group"
                  >
                    {/* Animated Background Effect */}
                    <div className="absolute inset-0 opacity-20">
                      <motion.div
                        animate={{
                          backgroundPosition: ['0% 0%', '100% 100%'],
                          opacity: [0.3, 0.6, 0.3],
                        }}
                        transition={{
                          duration: 8,
                          repeat: Infinity,
                          ease: "linear"
                        }}
                        className="absolute inset-0 bg-gradient-to-r from-purple-500/20 via-transparent to-purple-500/20 blur-xl"
                        style={{ backgroundSize: '200% 200%' }}
                      />
                    </div>

                    {/* Floating Orb Effect */}
                    <motion.div
                      animate={{
                        y: [-10, 10, -10],
                        opacity: [0.5, 0.8, 0.5],
                        scale: [1, 1.2, 1],
                      }}
                      transition={{
                        duration: 4,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                      className={`absolute -top-10 -right-10 w-32 h-32 rounded-full blur-2xl bg-gradient-to-r ${benefit.color} opacity-20`}
                    />

                    {/* Icon Container with Pulse Effect */}
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      className={`relative w-12 h-12 rounded-lg bg-gradient-to-br ${benefit.color} p-3 mb-4 group-hover:shadow-lg group-hover:shadow-purple-500/20`}
                    >
                      <motion.div
                        animate={{
                          scale: [1, 1.2, 1],
                          opacity: [0.7, 1, 0.7],
                        }}
                        transition={{
                          duration: 2,
                          repeat: Infinity,
                          ease: "easeInOut"
                        }}
                        className="absolute inset-0 rounded-lg bg-gradient-to-br from-purple-500/50 to-pink-500/50 blur"
                      />
                      <benefit.icon className="w-full h-full text-white relative z-10" />
                    </motion.div>

                    {/* Content with Hover Effects */}
                    <motion.h3 
                      className="text-xl font-bold mb-2 relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-purple-200 to-pink-200"
                    >
                      {benefit.title}
                    </motion.h3>
                    
                    <motion.p 
                      className="text-gray-400 relative z-10 group-hover:text-gray-300 transition-colors duration-300"
                    >
                      {benefit.description}
                    </motion.p>

                    {/* Energy Lines Effect */}
                    <motion.div
                      animate={{
                        opacity: [0, 0.5, 0],
                        rotate: [0, 90],
                      }}
                      transition={{
                        duration: 10,
                        repeat: Infinity,
                        ease: "linear"
                      }}
                      className="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(120,0,255,0.1)_0%,transparent_50%)] pointer-events-none"
                    />
                  </motion.div>
                ))}
              </div>

              <motion.div
                initial={{ y: 100 }}
                animate={{ y: showScrollButton ? 0 : 100 }}
                transition={{ 
                  duration: 0.3,
                  type: "spring",
                  stiffness: 260,
                  damping: 20
                }}
                className="fixed bottom-0 left-0 right-0 bg-black/95 backdrop-blur-sm p-3 sm:p-4 z-50 border-t border-gray-800 shadow-lg"
                style={{ 
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  transform: `translateY(${showScrollButton ? '0' : '100%'})`,
                  willChange: 'transform'
                }}
              >
                <div className="max-w-7xl mx-auto flex justify-between items-center gap-4">
                  <div className="text-base sm:text-lg font-bold text-white whitespace-nowrap">
                    <span className="text-purple-400">$59.99</span>
                    <span className="text-gray-400 text-sm sm:text-base line-through ml-2">$100.00</span>
                  </div>
                  <Link
                    to="/product"
                    className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
                      px-4 sm:px-8 py-2.5 sm:py-3 rounded-full 
                      text-sm sm:text-lg font-semibold 
                      hover:from-purple-700 hover:to-pink-700 transition-all duration-200 
                      shadow-lg hover:shadow-purple-500/20 whitespace-nowrap flex-shrink-0"
                  >
                    Buy Now - Save up to 60%
                  </Link>
                </div>
              </motion.div>

            
            </motion.div>
          </div>
        </motion.div>

        <motion.section 
          className="py-24 bg-black relative overflow-hidden"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              {/* Video Column */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="relative rounded-2xl overflow-hidden shadow-2xl"
              >
                <video
                  className="w-full rounded-2xl"
                  autoPlay
                  loop
                  muted={isMuted}
                  playsInline
                  poster="/video/thumbnail.jpg"
                >
                  <source 
                    src="/video/In the beginning we were one with the Earth Our.mp4" 
                    type="video/mp4" 
                  />
                  Your browser does not support the video tag.
                </video>
                {/* Optional: Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-r from-black/20 to-transparent"></div>
                
                {/* Sound Control Button - Tiny bit left from corner */}
                <button
                  onClick={() => setIsMuted(!isMuted)}
                  className="absolute bottom-0 right-1 p-3 bg-black rounded-full transition-all duration-200 hover:scale-110"
                >
                  {isMuted ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.536 8.464a5 5 0 010 7.072m2.828-9.9a9 9 0 010 12.728M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                    </svg>
                  )}
                </button>
              </motion.div>

              {/* Description Column */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="space-y-6"
              >
                <h2 className="text-3xl md:text-4xl font-bold">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                    Reconnect with Earth's Rhythm
                  </span>
                </h2>
                
                <p className="text-xl text-gray-300">
                  In the beginning, we were one with the Earth. Our bodies were perfectly tuned to its natural frequency of 7.83 Hz - the Schumann Resonance.
                </p>

                <p className="text-gray-400">
                  Today's digital world has disconnected us from this vital frequency. The Schumann device bridges this gap, bringing you back to nature's perfect harmony.
                </p>

                <div className="flex items-center gap-4 pt-4">
                  <Link 
                    to="/product" 
                    className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-pink-600 text-white px-6 py-3 rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-200 shadow-lg"
                  >
                    Experience It Now
                    <ArrowRightIcon className="h-5 w-5" />
                  </Link>
                </div>
              </motion.div>
            </div>
          </div>

          {/* Optional: Background Elements */}
          <div className="absolute inset-0 -z-10 bg-gradient-to-b from-black to-transparent pointer-events-none"></div>
        </motion.section>

        {/* Call to Action Section */}
        <motion.section
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="py-24 relative overflow-hidden"
        >
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <motion.h2 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-3xl md:text-4xl font-bold mb-6"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                Experience the Power of Schumann Resonance
              </span>
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-xl text-gray-400 max-w-3xl mx-auto mb-8"
            >
              While the scientific community continues to uncover new benefits of the Schumann frequency, you don't have to wait to experience its transformative effects. Our research team has successfully captured and replicated these natural frequencies in our innovative TheSchumann product line.
            </motion.p>
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10 mb-8"
            >
              <div className="grid md:grid-cols-3 gap-6 mb-8">
                <div className="text-center">
                  <div className="text-blue-400 text-4xl font-bold mb-2">94%</div>
                  <p className="text-gray-300 text-sm">Improved Sleep Quality</p>
                </div>
                <div className="text-center">
                  <div className="text-purple-400 text-4xl font-bold mb-2">47%</div>
                  <p className="text-gray-300 text-sm">Reduced Stress Levels</p>
                </div>
                <div className="text-center">
                  <div className="text-pink-400 text-4xl font-bold mb-2">82%</div>
                  <p className="text-gray-300 text-sm">Improved Concentration</p>
                </div>
              </div>
              <p className="text-xl text-gray-300 mb-8">
                "The clinical data is compelling. This device represents a significant breakthrough in frequency-based wellness technology." - Dr. James Wilson, Neuroscience Research Institute Cambridge UK
              </p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/product"
                className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full font-medium hover:from-purple-700 hover:to-pink-700 transition-all duration-200 shadow-lg"
              >
                Try Risk-Free for 30 Days
                <ArrowRightIcon className="ml-2 h-5 w-5" />
              </Link>
            </motion.div>
            <p className="text-gray-400 mt-4">
              🔒 30-Day Money Back Guarantee | ⚡ Free Express Shipping
            </p>
          </div>
        </motion.section>

        {/* Social Proof Section */}
        <motion.div 
          className="py-12 bg-gradient-to-r from-purple-900/40 to-black/40 backdrop-blur-sm border-t border-b border-purple-500/10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          <div className="max-w-7xl mx-auto px-4">
            <h3 className="text-center text-gray-400 text-sm mb-8 uppercase tracking-wider">Featured In</h3>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
              <motion.img
                src={nytLogo}
                alt="New York Times"
                className="h-4 md:h-6 w-auto opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
                whileHover={{ scale: 1.05 }}
              />
              <motion.img
                src={forbesLogo}
                alt="Forbes"
                className="h-4 md:h-6 w-auto opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
                whileHover={{ scale: 1.05 }}
              />
              <motion.img
                src={nasaLogo}
                alt="NASA"
                className="h-4 md:h-6 w-auto opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
                whileHover={{ scale: 1.05 }}
              />
              <motion.img
                src={scientificAmericanLogo}
                alt="Scientific American"
                className="h-5 md:h-8 w-auto opacity-70 hover:opacity-100 transition-opacity filter brightness-0 invert"
                whileHover={{ scale: 1.05 }}
              />
            </div>
          </div>
        </motion.div>

        {/* Why Choose Schumann Frequency Section */}
        <section id="why-choose-section" className="py-24 relative">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="text-center mb-16"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                  Why Choose Schumann Frequency?
                </span>
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto">
                Backed by extensive research and clinical trials, our technology harnesses Earth's natural 7.83 Hz frequency for optimal human wellness.
              </p>
            </motion.div>

            {/* Research Grid */}
            <div className="grid md:grid-cols-3 gap-8 mb-24">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-bold mb-4 text-blue-400">Cellular Research</h3>
                <p className="text-gray-300 mb-4">Studies at the molecular level show enhanced mitochondrial function when cells are exposed to the 7.83 Hz frequency.</p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">42% increase in cellular energy production</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">Improved DNA repair mechanisms</span>
                  </li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-bold mb-4 text-purple-400">Neurological Impact</h3>
                <p className="text-gray-300 mb-4">EEG studies demonstrate improved brain wave coherence and neural synchronization.</p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">28% better cognitive performance</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">Enhanced memory consolidation</span>
                  </li>
                </ul>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-bold mb-4 text-pink-400">Hormonal Balance</h3>
                <p className="text-gray-300 mb-4">Clinical studies reveal improved endocrine system regulation and stress response.</p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">36% reduction in cortisol levels</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 mt-1" />
                    <span className="text-gray-300">Optimized melatonin production</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
          {/* Background Gradient */}
          <div className="absolute inset-0 -z-10 bg-gradient-to-b from-black to-transparent"></div>
        </section>
      <motion.div 
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  className="py-24 relative overflow-hidden"
>
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      className="text-center mb-16"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-6">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
          Introducing Pro V2 Multi-Frequency
        </span>
      </h2>
      <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
        Experience the next evolution in frequency technology. Our most advanced and powerful device yet, 
        designed for professionals and demanding users who seek ultimate control and premium results.
      </p>
    </motion.div>

    {/* Pro Features Grid */}
    <div className="grid md:grid-cols-2 gap-12 mb-16">
      {/* Image Column */}
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        whileInView={{ opacity: 1, x: 0 }}
        className="space-y-6"
      >
        <div className="relative rounded-2xl overflow-hidden shadow-2xl">
          <img 
            src="/s-l1600.jpg" 
            alt="Schumann Pro V2 Device" 
            className="w-full h-[400px] object-cover rounded-2xl"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
        </div>
       
      
      </motion.div>

      {/* Features Column */}
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        whileInView={{ opacity: 1, x: 0 }}
        className="space-y-6"
      >
        <div className="bg-zinc-800/30 p-8 rounded-xl border border-white/10">
          <h3 className="text-2xl font-bold mb-6 text-purple-400">Professional-Grade Technology</h3>
          <ul className="space-y-4">
            <li className="flex items-start">
              <SparklesIcon className="h-6 w-6 text-purple-500 mr-3 mt-1" />
              <div>
                <span className="font-semibold text-white">Precision Engineering</span>
                <p className="text-gray-300 mt-1">Military-grade components ensure exact frequency generation with 99.99% accuracy</p>
              </div>
            </li>
            <li className="flex items-start">
              <DevicePhoneMobileIcon className="h-6 w-6 text-purple-500 mr-3 mt-1" />
              <div>
                <span className="font-semibold text-white">Advanced Control System</span>
                <p className="text-gray-300 mt-1">Digital OLED display with intuitive controls for precise frequency adjustments (0.1-999.99Hz)</p>
              </div>
            </li>
            <li className="flex items-start">
              <SignalIcon className="h-6 w-6 text-purple-500 mr-3 mt-1" />
              <div>
                <span className="font-semibold text-white">Extended Coverage</span>
                <p className="text-gray-300 mt-1">250ft² effective range with enhanced penetration through walls and obstacles</p>
              </div>
            </li>
          </ul>
        </div>

        <div className="bg-zinc-800/30 p-8 rounded-xl border border-white/10">
          <h3 className="text-2xl font-bold mb-6 text-blue-400">Premium Features</h3>
          <ul className="space-y-4">
            <li className="flex items-start">
              <WifiIcon className="h-6 w-6 text-blue-500 mr-3 mt-1" />
              <div>
                <span className="font-semibold text-white">Advanced EMF Protection</span>
                <p className="text-gray-300 mt-1">Dual-layer shielding technology with 98% EMF reduction capability</p>
              </div>
            </li>
            <li className="flex items-start">
              <ShieldCheckIcon className="h-6 w-6 text-blue-500 mr-3 mt-1" />
              <div>
                <span className="font-semibold text-white">Medical-Grade Quality</span>
                <p className="text-gray-300 mt-1">Registered facility production with certification</p>
              </div>
            </li>
          </ul>
        </div>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/product')}
          className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white py-4 px-8 rounded-xl font-semibold hover:from-purple-700 hover:to-pink-700 transition-all duration-200 shadow-lg"
        >
          Explore Pro V2 Features
        </motion.button>
      </motion.div>
    </div>
  </div>

  {/* Background Elements */}
  <div className="absolute inset-0 -z-10">
    <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black/20"></div>
    <motion.div
      animate={{
        scale: [1, 1.2, 1],
        opacity: [0.3, 0.5, 0.3],
      }}
      transition={{
        duration: 8,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
        w-[600px] h-[600px] 
        bg-purple-500/30 rounded-full blur-[100px]"
    />
  </div>
</motion.div>

{/* New Image Gallery Section */}
<motion.section
  initial={{ opacity: 0, y: 20 }}
  whileInView={{ opacity: 1, y: 0 }}
  viewport={{ once: true }}
  className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
>
  <h2 className="text-3xl font-bold text-center mb-12 bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
   Optimize Your Life 
  </h2>
  
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl"
    >
      <img 
        src="/pro slikce/Designer.jpg" 
        alt="Schumann Device Usage" 
        className="w-full h-[300px] object-cover object-top bg-black"
      />
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl"
    >
      <img 
        src="/pro slikce/Designer(1).jpg" 
        alt="EMF Protection" 
        className="w-full h-[300px] object-cover object-top bg-black"
      />
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl"
    >
      <img 
        src="/pro slikce/Designer(3).jpg" 
        alt="Sleep Enhancement" 
        className="w-full h-[300px] object-cover object-top bg-black"
      />
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl"
    >
      <img 
        src="/pro slikce/Designer(5).jpg" 
        alt="Wellness Technology" 
        className="w-full h-[300px] object-cover object-top bg-black"
      />
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl"
    >
      <img 
        src="/pro slikce/Designer(6).jpg" 
        alt="Natural Frequency" 
        className="w-full h-[300px] object-cover object-top bg-black"
      />
    </motion.div>

    <motion.div
      whileHover={{ scale: 1.02 }}
      className="relative rounded-2xl overflow-hidden shadow-xl bg-gradient-to-r from-purple-600/20 to-pink-600/20 flex items-center justify-center h-[300px]"
    >
      <Link 
        to="/product" 
        className="text-xl font-semibold text-white hover:text-purple-300 transition-colors"
      >
        Discover More →
      </Link>
    </motion.div>
  </div>
</motion.section>
        {/* Use Case Benefits Section */}
        <motion.section className="py-24 bg-black">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-16">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                Tailored Benefits for Every Need
              </span>
            </h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {/* Children & Students */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-2xl font-bold mb-6 text-blue-400">For Children</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Protects against digital device EMF exposure</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Improves focus during study hours</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Supports healthy sleep patterns</span>
                  </li>
                </ul>
              </motion.div>

              {/* Working Professionals */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-2xl font-bold mb-6 text-purple-400">For Work</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">34% improved focus & productivity</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Reduces workplace stress</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Neutralizes office EMF pollution</span>
                  </li>
                </ul>
              </motion.div>

              {/* Sleep Enhancement */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-2xl font-bold mb-6 text-pink-400">For Sleep</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">82% longer deep sleep phases</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Reduces stress and anxiety</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Improves overall well-being</span>
                  </li>
                </ul>
              </motion.div>

              {/* Seniors */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-2xl font-bold mb-6 text-teal-400">For Seniors</h3>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Supports cognitive function</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Enhances cellular regeneration</span>
                  </li>
                  <li className="flex items-start">
                    <CheckCircleIcon className="h-6 w-6 text-green-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Improves sleep quality</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </motion.section>

        {/* NASA & Space Research Section */}
        <motion.section className="py-24 bg-black relative overflow-hidden">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* NASA Content - First Grid */}
            <div className="grid md:grid-cols-2 gap-12 items-center mb-24">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
              >
                <h2 className="text-3xl md:text-4xl font-bold mb-6">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600">
                    NASA-Validated Technology
                  </span>
                </h2>
                <p className="text-gray-300 mb-6">
                  Originally developed to protect astronauts in space, where the absence of Earth's natural frequency led to significant health deterioration. NASA's research revealed that maintaining this vital 7.83 Hz frequency was crucial for:
                </p>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Maintaining astronaut cognitive function</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Preserving sleep-wake cycles in space</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Supporting overall crew well-being</span>
                  </li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <blockquote className="text-xl text-gray-300 italic">
                  "The implementation of Schumann frequency generators in spacecraft marked a turning point in space medicine, demonstrating the critical importance of Earth's natural frequency for human health."
                </blockquote>
                <p className="text-sm text-purple-400 mt-4">- Dr. Michael Chen, Former NASA Medical Research Director</p>
              </motion.div>
            </div>

            {/* Additional Research Studies */}
            <div className="grid md:grid-cols-2 gap-12 mt-24">
              {/* EMF Study */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-bold mb-4 text-blue-400">EMF Impact Research</h3>
                <p className="text-gray-300 mb-6">
                  Comprehensive analysis of electromagnetic fields' effects on human biological systems reveals significant cellular responses to Earth's natural frequencies.
                </p>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-blue-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Cellular voltage-gated calcium channels show direct response to EMF exposure</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-blue-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Therapeutic applications in neurological conditions</span>
                  </li>
                </ul>
                <p className="text-sm text-blue-400 mt-4">Source: PMC2656447 - National Institutes of Health</p>
              </motion.div>

              {/* Schumann Resonance Study */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="bg-zinc-800/30 p-8 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-bold mb-4 text-purple-400">Global Frequency Analysis</h3>
                <p className="text-gray-300 mb-6">
                  Recent studies demonstrate the fundamental role of Schumann resonances in human physiological rhythms and cognitive function.
                </p>
                <ul className="space-y-4">
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Correlation between human alpha rhythms and Earth's resonant frequencies</span>
                  </li>
                  <li className="flex items-start">
                    <SparklesIcon className="h-6 w-6 text-purple-500 mr-2 flex-shrink-0 mt-1" />
                    <span className="text-gray-300">Impact on cardiovascular and nervous system regulation</span>
                  </li>
                </ul>
                <p className="text-sm text-purple-400 mt-4">Source: PMC9189153 - National Institutes of Health</p>
              </motion.div>
            </div>
          </div>
          {/* Background Gradient */}
          <div className="absolute inset-0 -z-10 bg-gradient-to-b from-black to-transparent"></div>
        </motion.section>

        <motion.section
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
        >
          <motion.div
            whileHover={{ scale: 1.02 }}
            className="bg-gradient-to-r from-purple-600 to-pink-600 p-8 sm:p-12 rounded-2xl text-center shadow-xl"
          >
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Special Offer - Save 30% Today!</h2>
            <div className="flex justify-center items-center gap-3 mb-6">
              <span className="text-3xl sm:text-4xl font-bold">$59.99</span>
              <span className="text-xl sm:text-2xl text-gray-300 line-through">$100.00</span>
            </div>
            <p className="text-gray-200 mb-8 max-w-2xl mx-auto">
              Experience the power of Earth's natural 7.83 Hz frequency. Join thousands of satisfied customers who have transformed their well-being with Schumann resonance technology.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/product')}
              className="bg-white text-purple-600 px-8 py-4 rounded-full font-medium text-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              Get Your Device Now
            </motion.button>
            <p className="text-sm text-gray-200 mt-4">
              🛡️ 30-Day Money-Back Guarantee | 🚚 Free Shipping
            </p>
          </motion.div>
        </motion.section>

        {showDiscount && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              className="bg-gradient-to-r from-purple-900/90 to-black/90 p-6 rounded-2xl max-w-md w-full border border-purple-500/20 relative"
            >
              <button
                onClick={() => setShowDiscount(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-white"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>

              {showCode ? (
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-white mb-2">🔥 BLACK FRIDAY OFFER!</h3>
                  <div className="bg-purple-900/50 p-4 rounded-lg border border-purple-500/30 mb-4">
                    <p className="text-2xl font-mono text-white">WAVE10</p>
                  </div>
                  <div className="space-y-3 mb-6">
                    <p className="text-gray-300">
                      <span className="text-purple-400 font-semibold">Amazing Deal!</span> You've unlocked 40% off your order!
                    </p>
                    <p className="text-gray-300">
                      Get your Schumann Device for just $59.99 instead of $100!
                    </p>
                    <p className="text-gray-300">
                      Now is the perfect time to get the Family Package - protect your entire family with multiple devices at this incredible price!
                    </p>
                    <p className="text-sm text-purple-400 font-medium">
                      Limited time offer - Prices will never be this low again! ⚡️
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      setShowDiscount(false);
                      navigate('/product');
                    }}
                    className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
                      py-3 px-6 rounded-lg font-semibold w-full
                      hover:from-purple-700 hover:to-pink-700 
                      transition-all duration-200"
                  >
                    Claim Black Friday Deal Now
                  </button>
                </div>
              ) : (
                <>
                  <h3 className="text-2xl font-bold text-white mb-2">🔥 BLACK FRIDAY SPECIAL!</h3>
                  <p className="text-gray-300 mb-4">
                    Get 60% off your order! Enter your email to receive your exclusive Black Friday discount code.
                  </p>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="w-full px-4 py-3 rounded-lg bg-black/50 border border-purple-500/30 
                          text-white placeholder-gray-400 focus:outline-none focus:border-purple-500"
                        required
                      />
                    </div>

                    {error && <p className="text-red-400 text-sm">{error}</p>}

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white 
                        py-3 px-6 rounded-lg font-semibold 
                        hover:from-purple-700 hover:to-pink-700 
                        transition-all duration-200 
                        disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      {isSubmitting ? 'Sending...' : 'Get My Black Friday Discount'}
                    </button>
                  </form>
                </>
              )}
            </motion.div>
          </motion.div>
        )}

        {/* Pro CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
        >
          {/* Enhanced CTA Section */}
          <div className="text-center space-y-4 bg-gradient-to-r from-purple-50 to-pink-50 p-6 rounded-xl">
            <h4 className="text-xl font-semibold text-gray-800">Ready to Elevate Your Practice?</h4>
            <p className="text-gray-600">Join the professional community using the most advanced frequency therapy technology available</p>

            <button
              onClick={() => {
                const cartItem = {
                  id: 4,
                  name: "The Schumann Pro",
                  price: 159.00,
                  originalPrice: 200.00,
                  quantity: 1,
                  type: "pro"
                };
                addToCart(cartItem);
                setIsCartOpen(true);
              }}
              className="bg-gradient-to-r from-purple-600 to-pink-600 text-white 
                px-10 py-4 rounded-full text-lg font-semibold 
                hover:from-purple-700 hover:to-pink-700 
                transition-all duration-200 
                shadow-xl hover:shadow-purple-500/20
                transform hover:scale-105"
            >
              Upgrade to Schumann Pro
            </button>
            <p className="text-sm text-gray-500">Limited Availability - Professional Grade Equipment</p>
          </div>
        </motion.div>

        {/* Sticky Bottom Bar */}
        <motion.div
          initial={{ y: 100 }}
          animate={{ y: showScrollButton ? 0 : 100 }}
          transition={{ 
            duration: 0.3,
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
          className="fixed bottom-0 left-0 right-0 bg-black/95 backdrop-blur-sm p-3 sm:p-4 z-50 border-t border-gray-800 shadow-lg"
        >
          <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center gap-4">
            {/* Prices */}
            <div className="flex gap-4 text-sm sm:text-base">
              <div className="text-center">
                <div className="font-bold text-purple-400">$59.99</div>
                <div className="text-gray-400 text-xs sm:text-sm line-through">$100.00</div>
                <div className="text-green-400 text-xs">Save 40%</div>
              </div>
              <div className="text-center">
                <div className="font-bold text-[#FFD700]">$159.00</div>
                <div className="text-gray-400 text-xs sm:text-sm line-through">$200.00</div>
                <div className="text-green-400 text-xs">Save 20%</div>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex gap-3">
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                onClick={() => {
                  const cartItem = {
                    id: 1,
                    name: "The Schumann V1",
                    price: 59.99,
                    originalPrice: 100.00,
                    quantity: 1,
                    type: "standard"
                  };
                  addToCart(cartItem);
                  setIsCartOpen(true);
                }}
                className="px-4 sm:px-6 py-2.5 sm:py-3 bg-gradient-to-r from-purple-600 to-black text-white 
                  font-semibold rounded-lg hover:from-purple-700 hover:to-gray-900 
                  transition-all duration-300 transform hover:scale-105
                  flex items-center gap-2 whitespace-nowrap text-sm sm:text-base"
              >
                <span>Buy 7.83Hz V1</span>
              </motion.button>

              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                onClick={() => {
                  const cartItem = {
                    id: 4,
                    name: "The Schumann Pro",
                    price: 159.00,
                    originalPrice: 200.00,
                    quantity: 1,
                    type: "pro"
                  };
                  addToCart(cartItem);
                  setIsCartOpen(true);
                }}
                className="px-4 sm:px-6 py-2.5 sm:py-3 bg-white font-semibold rounded-lg 
                  hover:bg-gray-100 transition-all duration-300 transform hover:scale-105
                  border border-gray-200 flex items-center gap-2 whitespace-nowrap text-sm sm:text-base"
              >
                <span className="bg-gradient-to-r from-[#FFD700] to-[#B8860B] bg-clip-text text-transparent">
                  Go Pro V2
                </span>
              </motion.button>
            </div>
          </div>
        </motion.div>

      
      </div>
    </>
  );
} 