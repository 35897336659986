import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRightIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-black text-white border-t border-white/10">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          {/* Company Info */}
          <div className="space-y-4">
            <h3 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
              TheSchumann
            </h3>
            <p className="text-gray-400 text-sm leading-relaxed">
              Enhancing wellness through Earth's natural frequency. Experience the power of 7.83 Hz.
            </p>
            <div className="flex space-x-4 pt-4">
              <a 
                href="https://www.facebook.com/people/The-Schumann/61568430865401/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
              >
                <FaFacebook className="h-6 w-6" />
              </a>
              <a 
                href="https://www.instagram.com/theschumannresonance/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
              >
                <FaInstagram className="h-6 w-6" />
              </a>
              <a 
                href="https://www.tiktok.com/@theschumannresonnace"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
              >
                <FaTiktok className="h-6 w-6" />
              </a>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-6 text-gray-200">Quick Links</h3>
            <ul className="space-y-3">
              <li>
                <Link to="/product" className="text-gray-400 hover:text-purple-400 text-sm transition-colors duration-200 flex items-center group">
                  <ArrowRightIcon className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-all duration-200" />
                  Shop
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-purple-400 text-sm transition-colors duration-200 flex items-center group">
                  <ArrowRightIcon className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-all duration-200" />
                 Scientific Research
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-400 hover:text-purple-400 text-sm transition-colors duration-200 flex items-center group">
                  <ArrowRightIcon className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-all duration-200" />
                 About The Project 
                </Link>
              </li>
              <li>
                <Link to="/return-policy" className="text-gray-400 hover:text-purple-400 text-sm transition-colors duration-200 flex items-center group">
                  <ArrowRightIcon className="h-4 w-4 mr-2 opacity-0 group-hover:opacity-100 transition-all duration-200" />
                 Return Policy
                </Link>
              </li>
              <li>
                <Link 
                  to="/affiliate" 
                  className="text-gray-400 hover:text-purple-400 transition-colors duration-200"
                >
                  
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-lg font-bold mb-6 text-gray-200">Contact</h3>
            <ul className="space-y-3 text-sm text-gray-400">
              <li className="hover:text-purple-400 transition-colors duration-200">
                <a href="mailto:support@theschumann.com">support@theschumann.com</a>
              </li>
              <li className="text-gray-400">
                Schumann Technologies GmbH<br />
                <Link 
                  to="/affiliate" 
                  className="block text-gray-400 hover:text-purple-400 transition-colors duration-200 mt-3"
                >
                  Affiliate Program - Join Now
                </Link>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <NewsletterForm />
          </div>
        </div>
        <div className="text-sm text-gray-400 mt-4">
        <p className="text-sm text-gray-700">
      By ordering, you agree to our{' '}
    
     
      {' '}
      <Link to="/terms-of-service" className="text-purple-400 hover:text-purple-500 underline">
        Terms of Service
      </Link>.
    </p>
</div>
        <div className="mt-16 pt-8 border-t border-white/10 text-center text-sm text-gray-400">
          <p>© {new Date().getFullYear()} Schumann. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

function NewsletterForm() {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiYjk4ZmZlNGMwM2VjNWQ2NjA0OTk5Y2JmOWVmMzNlYTJiZWI5N2YyNWY1MTNjYTkwOTRhNDk0N2MzMDNiNzQyMzVhZmY2YWI0MmNmMGQwNzkiLCJpYXQiOjE3MzE5Njg4MjguNDYzMzU1LCJuYmYiOjE3MzE5Njg4MjguNDYzMzU3LCJleHAiOjQ4ODc2NDI0MjguNDU5NDQ3LCJzdWIiOiIxMTkzMjcyIiwic2NvcGVzIjpbXX0.jlv3EMuvlqTZ3GXxHAgOdEL9TqMtyUkmmdKaRikwEi03izMl_1Tvg5g-sWUV39KYKx9DsAX2H24PjBLSJhb8ceYAtvhjlnIW06-ELXdyY7dHhzNa37rZe2IzCbj423NxcQDixVFdCvXr7iyZbsO0hu0wNlItDa5MTOn_RhLN229kR-Ab1ExUFPZtHuH8FOcgZjfHP0dVc_MXF0iXt1uD-Kd5LxfhhUTMrBpEZFaF0cEefL6-9bfiCUbHI0Zbd0E6vbby1ZHRVZkWc47F688RbBM1SGZ-j99nx-3ZpRoU-aU_u2Y443BEnlA-wlMGh_6-Mk0ctte8lf37AstWf7Sy37E50LthvI1as3673hraoJ0rl-v_FgujannHglcsJYCrDbaXDYiOSvFgzjQRXjTr7ln3b0FFaiBwOExNFKiyjr8LABEzWSHM-cjXzb1UvoNt5IvZC8CEP1VC1DZaGDDDNxjVgwKWX0yTUdPMWa64wyyiziJFlJi1hxd1qDXgHXbF59XUWCp1ID8Utuweli-XwD4cyJgfmlcx9ZqlnNzymmqeUMPrADHKKom091BWzoGz8dDs3iI1o1c-GJyF7NzLc6BCzdRXtMajbH3Eryd0XLWCv8YDHqH8kKzLnB_6Ky_3X26liid_PTpIShCU40MsAzw-PF2-ea8l1lkP-qMeCmM'
        },
        body: JSON.stringify({
          email: email,
          groups: ['138265391280424210'],
          status: 'active',
          subscribed: true,
          fields: {
            name: 'Newsletter Subscriber',
            source: 'Website Footer'
          }
        })
      });

      const data = await response.json();

      if (response.ok) {
        setSubscriptionStatus('success');
        setEmail('');
        setTimeout(() => setSubscriptionStatus(null), 3000);
      } else {
        console.error('Subscription error:', data);
        setSubscriptionStatus('error');
      }
    } catch (error) {
      console.error('Subscription error:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mt-8">
      {/* Add subscription header and description */}
      <div className="mb-6">
        <h3 className="text-lg sm:text-xl font-semibold text-white mb-2">
          Join the Schumann Community 🌍
        </h3>
        <p className="text-gray-400 text-sm sm:text-base">
          Subscribe for exclusive deals, latest research on 7.83 Hz frequency, and wellness tips. 
          Stay tuned for special promotions and expert insights into Earth's natural healing frequency!
        </p>
      </div>

      <form onSubmit={handleSubscribe} className="mt-4 sm:flex sm:max-w-md">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-transparent rounded-md appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
          placeholder="Enter your email"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 ${
              isSubmitting ? 'opacity-75 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? (
              <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : (
              'Subscribe'
            )}
          </button>
        </div>
      </form>

      {/* Success/Error Messages with emoji */}
      {subscriptionStatus && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className={`mt-2 text-sm ${
            subscriptionStatus === 'success' 
              ? 'text-green-400' 
              : 'text-red-400'
          }`}
        >
          {subscriptionStatus === 'success' 
            ? '✨ Welcome to the Schumann community! Check your inbox soon.' 
            : '❌ Oops! Something went wrong. Please try again.'}
        </motion.div>
      )}
    </div>
  );
}

export default Footer; 