import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

const Section = ({ title, children, isOpen, onClick }) => {
  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        onClick={onClick}
        className="flex justify-between items-center w-full py-4 px-6 text-left hover:bg-gray-50 transition-colors"
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="px-6 pb-4 prose prose-sm max-w-none">
          {children}
        </div>
      )}
    </div>
  );
};

const PrivacyPolicy = () => {
  const [openSections, setOpenSections] = useState(new Set());

  const toggleSection = (index) => {
    const newOpenSections = new Set(openSections);
    if (newOpenSections.has(index)) {
      newOpenSections.delete(index);
    } else {
      newOpenSections.add(index);
    }
    setOpenSections(newOpenSections);
  };

  const sections = [
    {
      title: "1. Introduction and Acceptance of Terms",
      content: (
        <>
          <p>By accessing, browsing, or making a purchase from The Schuman Technologies ("we," "our," "us," "the Company"), you explicitly acknowledge, understand, and agree to be bound by all terms, conditions, policies, and notices contained or referenced herein. If you do not agree with these terms, you must immediately cease any interaction with our site or services.</p>
        </>
      )
    },
    {
      title: "2. Nature of Business Operations",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">2.1 Outsourcing Disclosure</h3>
          <p>We explicitly operate as a outsourcing business. By making a purchase, you acknowledge and agree to the following:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Products are sourced from third-party manufacturers and suppliers</li>
            <li>Items are shipped directly from manufacturers or their designated facilities</li>
            <li>Processing and shipping times may vary significantly</li>
            <li>Product packaging may differ from displayed images</li>
            <li>Manufacturing locations may change without notice</li>
          </ul>
          
          <h3 className="text-lg font-semibold mb-2">2.2 Explicit Acknowledgment</h3>
          <p>By placing an order, you explicitly acknowledge all dropshipping terms and conditions.</p>
        </>
      )
    },
    {
      title: "3. Comprehensive Liability Limitations",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">3.1 General Disclaimers</h3>
          <p>The Company explicitly disclaims all liability for:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>Any and all product defects</li>
            <li>Manufacturing variations or inconsistencies</li>
            <li>Color variations from displayed images</li>
            <li>Size discrepancies within reasonable margins</li>
            <li>Shipping delays or complications</li>
            <li>Customs or import issues</li>
          </ul>
          <p><strong>Under no circumstances will the Company be held liable for any damages, direct or indirect, arising from the use of our products or services.</strong></p>
        </>
      )
    },
    {
     
      title: "5. International Operations",
      content: (
        <>
          <h3 className="text-lg font-semibold mb-2">5.1 Cross-Border Commerce</h3>
          <p>As an EU-based company serving US customers with Chinese manufacturing:</p>
          <ul className="list-disc pl-5 mb-4">
            <li>All transactions are processed in USD</li>
            <li>EU consumer protection laws apply</li>
            <li>GDPR compliance is maintained</li>
            <li>International shipping regulations apply</li>
            <li>Customs and import duties may apply</li>
          </ul>
        </>
      )
    },
    {
      title: "6. LLM-Generated Content Disclaimer",
      content: (
        <>
          <p>All content on this website, including but not limited to testimonials, names, and other personal details, is entirely fictional. Any resemblance to actual persons, living or dead, is purely coincidental.</p>
          <p>Additionally, we make no guarantees or confirmations about the accuracy, reliability, or effectiveness of any products, services, or information provided on this website. All content is created for illustrative purposes and is subject to change.</p>
        </>
      )
    },
    {
      title: "7. Contact Information",
      content: (
        <>
          <p>For all inquiries:</p>
          <p>The Schuman Technologies</p>
          <p>Email: support@theschumann.com</p>
          <p>Hours: 24/7 </p>
        </>
      )
    }
  ];

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="bg-gray-50 border-b border-gray-200 py-6 px-6">
        <h1 className="text-2xl font-semibold">Privacy Policy and Terms of Service</h1>
        <p className="text-sm text-gray-500">Last Updated: November 13, 2024</p>
      </div>
      <div className="p-0">
        {sections.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            isOpen={openSections.has(index)}
            onClick={() => toggleSection(index)}
          >
            {section.content}
          </Section>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;

  